.location {
  padding-bottom: 2rem;

  .map-container {
    height: 80vh;
    width: 80%;
    margin: auto;
  }

  .we-are-at {
    padding-top: 1rem;
    padding-bottom: 8px;
    font-weight: bold;
  }

  .address {
    padding-bottom: 1rem;
  }
}