#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

nav {
  background-color: #498899;
}

.navbar-light a.navbar-brand {
  font-weight: bold;
  color: #AE8930;
}