.about {

  .card-container {
    max-width: 95%;
  }

  .robert-headshot {
    width: 70%;
    height: auto;
    margin: auto;
    border-radius: 8px;
  }
}